<template>
	<div class="content qrcodeDiv">
		<h2>请手机截屏保存本站二维码到手机相册，下次访问无忧！</h2>
		<div class="qrcodeDiv1">
			<p>正确扫描二维码方式：</p>
			<p>使用手机浏览器或其他浏览器扫描二维码，即可跳转到最新域名！</p><hr />
			<div class="qecoEwmImg">
				<div class="sysDiv">
					<i class="el-icon-full-screen"></i>
					<span>扫一扫</span>
				</div>
				<img src="../../public/img/tan12.png" />
			</div>
			<div class="qrcodeTbtx">
				特别提醒！请勿使用微信客户端扫描二维码：微信禁止域名的访问
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		components:{
			
		},
		data() {
		  return {
			
		  }
		},
		created() {
			
	    },
		mounted() {
		    
		},
		methods: {
			
		}
	}
</script>

<style>
	.qrcodeDiv1 p{
		color: red;
		margin-bottom: 0.625rem;
		font-size: 1.125rem;
	}
	.qecoEwmImg{
		text-align: center;
		margin-top: 0.625rem;
	}
	.qecoEwmImg .sysDiv{
		width: 60%;
		background: rgba(0, 0, 0, 0.6);
		color: white;
		height: 60px;
		line-height: 60px;
		font-size: 24px;
		display: inline-block;
	}
	.qrcodeDiv1 img{
		width: 60%;
	}
	.qrcodeTbtx{
		background: #ffffcc;
		border: 1px solid #339900;
		padding: 20px;
		color: #FF0000;
		margin-top: 3.125rem;
	}
</style>
