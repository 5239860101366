<template>
  <div id="app">
   	<!--head star -->
   	<header class="headDiv" v-if="$route.meta.showNavbar">
   		<el-row>
   		  <el-col :xs="7" :sm="7" :md="7" :lg="7" :xl="7">
   			<div @click="toHome" class="grid-content bg-purple">
   			<p>首页</p>
   			<!-- <p>防封地址：91tantan.com</p> -->
   		  </div></el-col>
		  <!-- :xs="20" -->
   		  <el-col :xs="17" :sm="17" :md="17" :lg="17" :xl="17"><div class="grid-content bg-purple-right" style="padding-top: 4px;">
   			 <el-button type="danger" @click="toMallInfo" plain>账户</el-button>
			 <el-button type="danger" @click="toBought" plain>收藏</el-button>
			 <!-- <el-button type="danger" @click="toBoughtSound" plain>已购买</el-button> -->
			 <!-- <el-button type="danger" @click="toJoin" plain>代理账户</el-button> -->
   		  </div></el-col>
   		</el-row>
   	</header>
	<!-- <div class="jddlDiv" v-if="openUrlxText" @click="openNewWindowJd">
		<el-alert
			:title="openUrlxText"
			type="success"
			:closable="false"
			show-icon>
		</el-alert>
	</div> 
	<div class="zfwybzDiv" v-else>
		<el-alert
			title="本站只服务于海外华裔, 未满18周岁禁止使用"
			type="warning"
			show-icon>
		</el-alert>
	</div>-->
	<div class="zfwybzDiv" v-if="$route.meta.showNavbar">
		<el-alert
			title="本站只服务于海外华裔, 未满18周岁禁止使用"
			type="warning"
			show-icon>
		</el-alert>
	</div>
	<div v-if="!(path ==='/Recharge2' || path ==='/Sound' || path ==='/Join' || path ==='/Promote' || path ==='/Popular' || !$route.meta.showNavbar)" class="searchDiv">
		<div class="hidden-sm-and-up" @click="toPopular">
			<el-input :placeholder="seachrPlar" v-model="input1" class="input-with-select">
			 <!-- <el-button @click="addNumAxios" slot="append" icon="el-icon-search"></el-button> -->
			</el-input>
		</div>
	</div>
	<!-- <el-dialog
	  title="备用域名,截图保存!"
	  :visible.sync="centerDialogVisible"
	  width="81%"
	  top="15vh">
	  <div id="dialogBody">
		  <div v-if="(GLOBAL.domainData!='' || GLOBAL.domainData!=null)">
			  <p v-for="(item, index) in GLOBAL.domainData" :key="index">
				  地址{{index+1}}：<a href="javascript:;">{{item.address}}</a>
			  </p>
		  </div>
		  <div v-if="(GLOBAL.domainData=='' || GLOBAL.domainData==null)">
			  <p>地址1：<a href="javascript:;">zafch.com</a></p>
			  <p>地址2：<a href="javascript:;">loqdt.com</a></p>
			  <p>地址3：<a href="javascript:;">tbvke.com</a></p>
		  </div>
		 <p>请截图保存，以防找不到回家的路！</p>
		  <p>推荐使用[Chrome/手机自带]等浏览器打开网站！</p>
		  <p>移动网络经常屏蔽本站，无法访问请更换网络!</p>
	  </div>
	</el-dialog> -->
	<el-dialog
	  :visible.sync="centerDialogVisibleVip"
	  width="80%"
	  top="25vh"
	  center>
	  <span slot="title" class="dialog-title">
	     温馨提示
	  </span>
	  <div class="dialogBody" style="margin: 40px 30px;">
		 <p style="font-weight: 700;font-size: 16px;text-align: center;">{{seachrTitle}}</p>
	  </div>
	  <span slot="footer" class="dialog-footer">
	   <el-button type="danger" @click="centerDialogVisibleVip = false" round><i class="el-icon-orange"></i> 我再看看</el-button>
	   <el-button type="warning" @click="toRecharge2" round><i class="el-icon-unlock"></i> {{seachrButtonText}}</el-button>
	  </span>
	</el-dialog>
	<!-- <Xuanfu v-if="!(path ==='/Recharge2' || path ==='/Zfss' || path ==='/Sound' || path ==='/Mp3' || path ==='/Mp3Play')" :text="'哈'"></Xuanfu>
	 --><el-backtop :visibility-height="1300" :right="5" :bottom="80"></el-backtop>
	<!-- <router-view></router-view> -->
	<keep-alive>
	    <router-view v-if="$route.meta.keepAlive">
	        <!-- 这里是会被缓存的视图组件，比如 page1,page2 -->
	    </router-view>
	</keep-alive>
	
	<router-view v-if="!$route.meta.keepAlive">
	    <!-- 这里是不被缓存的视图组件，比如 page3 -->
	</router-view>
  </div>
</template>

<script>
/* import Xuanfu from './components/xuanfu.vue' */
export default {
	  name: 'app',
	  components:{
	  	
	  },
	  data() {
		return {
			userId:'',
			equipmentName:'android',
			centerDialogVisible: false,
			centerDialogVisibleVip:false,
			centerDialogVisibleTs:false,
			path:'',
			input1:'',
			videoTypes:"auto",
			towFiveData:'',
			showClosex:false,
			zfAddress:'hqwx',
			zfDomain:'dsfvde.chqskk.cn',
			shareCotent:'',
			wftsData:'',
			seachrTitle:'开通VIP即可搜索海量资源',
			seachrButtonText:'立即开通',
			seachrPlar:'搜索 25,728,313 个视频',
			zfssIndex:2,
			zfssTitlex:'充值完成20分钟后未到账',
			lxfsqq:"",
			lxfsNumber:"",
			lxfstele:"",
			lxfsTeleNumber:"",
			lxfsgmail:"",
			lxfsgmailNumber:"",
			openUrlx:"",
			openUrlxText:"",
		}
	  },
	  created(){
		this.GLOBAL.serverSrc = "https://"+document.domain+"/tantan/";
		this.GLOBAL.serverSrcZf = "https://"+document.domain+"/tantanzf/";
		this.GLOBAL.serverSrcSs = "https://"+document.domain+"/tantanss/";
		this.GLOBAL.host=document.domain;
		this.getEquipmentName();
	  	this.loginAxios();
		this.domainAxios();
		this.getWfzftsAxios();
	  },
	  mounted () {
		  this.path = this.$route.path;
		   /* const script = document.createElement('script')
		   script.src = 'https://s9.cnzz.com/z_stat.php?id=1280609500&web_id=1280609500'
		   script.language = 'JavaScript'
		   document.body.appendChild(script) */
	  },
	  watch: {
	      '$route' (to,from) {
			this.path = to.path;
			 /* if (window._czc) {
			  let location = window.location
			  let contentUrl = location.pathname + location.hash
			  let refererUrl = '/'
			  window._czc.push(['_trackPageview', contentUrl, refererUrl])
			} */
	      }
	  },
	  methods: {
		 openNewWindowJd() {
			window.open('https://'+this.openUrlx, '_blank');
		},
		toMallInfo(){
			this.$router.push({
				name: "My",
				query: {
					userId: this.userId,
				}
			});
		},
		toHome(){
			this.$router.push({
				name: "Index",
				query: {
					userId: this.userId,
				}
			});
		},
		toBought(){
			this.$router.push({
				name: "Bought",
				query: {
					userId: this.userId,
				}
			});
		},
		toBoughtSound(){
			this.$router.push({
				name: "BoughtSound",
				query: {
					userId: this.userId,
				}
			});
		},
		toRecharge2(){
			this.centerDialogVisibleVip = false;
			this.$router.push({
			　　path: '/Recharge2', 
			    query: {
					userId:this.userId
				}
			});
		},
		toJoin(){
			this.$router.push({
				name: "Join"
			});
		},
		toPopular(){
			this.$router.push({
				name: "Popular",
				query: {
					userId: this.userId,
				}
			});
		},
		loginAxios(){
			var that = this;
			const loading = this.$loading({
			  lock: true,
			  text: '拼命加载中...',
			  spinner: 'el-icon-loading',
			  background: 'rgba(0, 0, 0, 0.7)'
			});
			var params = new URLSearchParams();
			params.append('id', localStorage.getItem("tantan_userId_ds"));
			params.append('uid', this.GetQueryString("uid"));
			params.append('uids', this.GetQueryString("uids"));
			params.append('sbName', this.equipmentName);
			params.append('type', "1");
			this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/user/login',params)
			  .then(function (response) {
				  loading.close();
				  var datas = response.data.data;
				  if(response.data.code==1){
					 that.userId = datas.id; 
					 localStorage.setItem("tantan_name",datas.name);
					 localStorage.setItem("tantan_userId_ds",datas.id);
				  }else{
					   that.open('通知','网络出错，请刷新重试！');
				  }
			  })
			  .catch(function (error) {
				  loading.close();
				  that.centerDialogVisible = true;
				  console.log(error);
			  });
		},
		open(title,content) {
			this.$notify({
			  title: title,
			  message: content,
			  offset: 100
			});
		},
		GetQueryString(name){
		     var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
		     var r = window.location.search.substr(1).match(reg);
		     if(r!=null)return  unescape(r[2]); return null;
		},
		getEquipmentName(){
			let p = navigator.platform;
			let u = navigator.userAgent;
			let is_android = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
			let is_ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
			if (p == "Win32" || p == "Win64" || p == "MacPPC" || p == "MacIntel" || p == "X11" || p == "Linux i686") {//PC调试环境
				this.videoTypes = "customHls";
				this.equipmentName = p;
			}else {
				this.videoTypes = "auto";
			    if (is_android) {//Android终端
			        //console.log('Mobile环境--Android移动端');
					this.equipmentName = "android";
			    }
			    else if (is_ios) {//IOS终端
			        //console.log('Mobile环境--IOS移动端');
					this.equipmentName = "iOS";
			    }else{
					this.equipmentName = "android";
				}
			}
		},
		domainAxios(){
			var that = this;
			var params = new URLSearchParams();
			params.append('version', '3');
			params.append('status', '0');
			params.append('type', '2');
			this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/zhuanfa/listLuodi',params)
			  .then(function (response) {
				  var datas = response.data.data;
				  if(response.data.code==1){
					var numx = datas.length;
					if(numx>0){
						that.GLOBAL.domainData = datas;
					}
				  }
				  if(that.path!="/Modify" && that.path!="/Recharge2"){
					that.centerDialogVisible = true;
				  }
			  })
			  .catch(function (error) {
				  if(that.path!="/Modify" && that.path!="/Recharge2"){
					that.centerDialogVisible = true;
				  }
				  console.log(error);
			  });
		},
		getWfzftsAxios(){
			var that = this;
			var params = new URLSearchParams();
			params.append('type', '');
			this.axios.post(this.GLOBAL.serverSrcZf+'mobile/tantan/zfss/wftsList',params)
			  .then(function (response) {
				  if(response.data.code==1){
				    var datas = response.data.data;
					that.wftsData = datas;
					for (var i = 0; i < datas.length; i++) {
						if(datas[i].type=='3'){
							that.shareCotent = datas[i].name;
						}
						if(datas[i].type=='4'){
							that.seachrPlar = datas[i].name;
							if(datas[i].remarks){
								that.openUrlx = datas[i].remarks;
								that.openUrlxText= "令你眼前一亮的站!!! 网址: "+datas[i].remarks+" (点击查看)";
							}
						}
						if(datas[i].type=='5'){
							if(datas[i].name=="申诉开启"){
								that.zfssIndex = 1;
								that.zfssTitlex = datas[i].remarks;
							}
						}
						if(datas[i].type=='6'){
							that.lxfsqq = datas[i].name;
							that.lxfsNumber = datas[i].remarks;
						}
						if(datas[i].type=='7'){
							that.lxfstele = datas[i].name;
							that.lxfsTeleNumber = datas[i].remarks;
						}
						if(datas[i].type=='8'){
							that.lxfsgmail = datas[i].name;
							that.lxfsgmailNumber = datas[i].remarks;
						}
					}
				  }
			  })
			  .catch(function (error) {
				console.log(error);
			});
		}
	}
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 55px;
  width: 100%;
}
iframe[id^="head__"] { display: none !important; }
iframe[id^="utp__"] { display: none !important; }
.el-dialog__header{
   font-weight: 700;
   text-align: center;
}
 .el-dialog__wrapper .el-dialog__body{
	 padding: 0px;
 }
 #dialogBody,.tsDialogBody{
	 padding: 23px 5px 23px 20px;
	 color: white;
	 font-weight: 700;
	background: rgb(245,108,108);
	background: linear-gradient(90deg, rgba(245,108,108,1) 50%, rgba(252,176,69,1) 100%);
 }
#dialogBody p{
   margin-bottom: 0.7rem;
   font-size: 1.2rem;
}
.tsDialogBody p{
   line-height: 36px;
}
#dialogBody p a {
   color: rgb(220, 225, 250);
   text-decoration: solid;
}
.czdialog .el-dialog__header{
	padding: 0px;
}
.towFiveP{
	line-height: 30px;
	font-size: 1rem;
	font-weight: bold;
	text-align: center;
	padding: 18px 30px;
}
.towFiveP2{
	padding: 18px;
}
#app .zpdiv{
	position: fixed;
	bottom: 0px;
	z-index: 10;
}
</style>
